import classNames from 'classnames';
import { isNil } from 'lodash';
import { CircularProgress } from '@mui/material';

import './LoadingSpinner.scss';

interface IProps {
  size?: number;
  styleName?: string;
  className?: string;
}

const LoadingSpinner = ({ size, className }: IProps) => {
  return (
    <CircularProgress
      size={size}
      className={className}
      styleName={classNames('loading default-color', { 'default-size': isNil(size) })}
    />
  );
};

export default LoadingSpinner;
