import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { isNil, isNull, isEmpty } from 'lodash';
import classNames from 'classnames';

import { sendReceipt } from 'actions/payment';
import { routes } from 'utils/routeHelper';
import { getReceiptTemplatePDFUri, convertListOfSharedEmails } from 'utils/payment';
import { isCordovaApp } from 'utils/constants';
import { selectedPaymentSelector } from 'selectors/payment';

import { Header } from 'components/layouts';
import { Col, Fab, TextInput, Dialog, PaymentOverviewBody, ReceiptTemplate, KeyboardWrapper } from 'components/widgets';

import './PaymentOverview.scss';

const INVALID_EMAILS_ERROR_MESSAGE = 'Seems like the format is invalid';

const PaymentOverview = () => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [sharedEmails, setSharedEmails] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);

  const dispatch = useDispatch();
  const { isScheduledPayment, selectedPayment } = useSelector((state: IRootState) => {
    const { isScheduledPayment, data } = selectedPaymentSelector(state);
    return {
      isScheduledPayment: isScheduledPayment || false,
      selectedPayment: data,
    };
  });
  if (isNull(selectedPayment)) return <Navigate to={routes.HOME} replace />;

  const getReceiptTemplateFilenameSuffix = (): string => {
    if (isScheduledPayment) {
      return (selectedPayment as IScheduledPayment)!.transaction!.orderId;
    }

    return (selectedPayment as IPayment)!.confirmId;
  };

  const shareReceiptByAppDone = () => setIsSending(false);

  const shareReceiptByApp = async () => {
    setIsSending(true);

    const filenameSuffix = getReceiptTemplateFilenameSuffix();
    const { dataUri, filename } = await getReceiptTemplatePDFUri(filenameSuffix);
    const pdfFile = dataUri.replace('data:application/pdf', `data:application/pdf;df:${filename}`);
    const options = {
      message: null,
      subject: null,
      files: [pdfFile],
      url: null,
    };
    window.plugins.socialsharing.shareWithOptions(options, shareReceiptByAppDone, shareReceiptByAppDone);
  };

  const onShareReceipt = () => {
    if (isCordovaApp) {
      shareReceiptByApp();
    } else {
      setIsOpenDialog(true);
    }
  };

  const onChangeSharedEmails = (sharedEmails: string) => {
    setSharedEmails(sharedEmails);
    setErrorMessage(null);
  };

  const onCloseShareReceiptDialog = () => {
    if (isSending) return;

    setIsOpenDialog(false);
    setSharedEmails('');
    setErrorMessage(null);
  };

  const onSendReceipt = async () => {
    setIsSending(true);

    const filenameSuffix = getReceiptTemplateFilenameSuffix();
    const { dataUri } = await getReceiptTemplatePDFUri(filenameSuffix);
    const emails = convertListOfSharedEmails(sharedEmails);
    if (isNull(emails)) {
      setIsSending(false);
      setErrorMessage(INVALID_EMAILS_ERROR_MESSAGE);
    } else {
      dispatch(
        sendReceipt({
          data: { emails, dataUri },
          onSuccess: () => {
            setIsSending(false);
            onCloseShareReceiptDialog();
          },
          onError: () => {
            setIsSending(false);
            onCloseShareReceiptDialog();
          },
        }),
      );
    }
  };

  const isSendingReceiptButtonDisabled = () => {
    return isEmpty(sharedEmails) || isSending || !isNil(errorMessage);
  };

  return (
    <>
      <div styleName="wrapper">
        <Header />
        <Col xs={12} sm={6} md={4}>
          <KeyboardWrapper>
            <PaymentOverviewBody isScheduledPayment={isScheduledPayment} payment={selectedPayment} />
            <Fab onClick={onShareReceipt} disabled={isCordovaApp && isSending}>
              <span>Share receipt</span>
            </Fab>
          </KeyboardWrapper>
        </Col>
      </div>
      <ReceiptTemplate isScheduledPayment={isScheduledPayment} payment={selectedPayment} />
      <Dialog
        isOpen={isOpenDialog}
        onClose={onCloseShareReceiptDialog}
        title="Sharing receipt via Email"
        actions={[
          {
            text: 'Cancel',
            type: 'negative',
            onClick: onCloseShareReceiptDialog,
          },
          {
            text: isSending ? 'Sending...' : 'Send Receipt',
            disabled: isSendingReceiptButtonDisabled(),
            onClick: onSendReceipt,
          },
        ]}
      >
        <div styleName={classNames('dialog', { error: !isNil(errorMessage) })}>
          Enter the Email address(es) you’d like to send this receipt to. Seperate with a comma to add more than one.
          <TextInput
            label="Email address"
            value={sharedEmails}
            error={!isNil(errorMessage)}
            errorMessage={errorMessage || ''}
            onChange={onChangeSharedEmails}
          />
        </div>
      </Dialog>
    </>
  );
};

export default PaymentOverview;
