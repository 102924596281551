import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { setSignInSuccessUrl } from 'actions/user';
import { ScratchAuth } from 'hooks/useAuthenticationSubscriber';

type RequireAuthProps = {
  children: JSX.Element;
  currentUser: ScratchAuth;
  redirectTo: string;
};

const RequireAuth = ({ children, currentUser, redirectTo }: RequireAuthProps) => {
  const dispatch = useDispatch();
  const currentUserRef = useRef(currentUser);
  const location = useLocation();

  const isAuthenticated = currentUser && currentUser.scratchBorrowerId;

  if (isAuthenticated) {
    return children;
  }
  const saveSignInSuccessUrlIfNeeded = () => {
    if (!currentUserRef.current) {
      dispatch(setSignInSuccessUrl(location.pathname));
    }
  };

  saveSignInSuccessUrlIfNeeded();
  return <Navigate to={redirectTo} replace />;
};

export default RequireAuth;
