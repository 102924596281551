import { FC } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { routes } from 'utils/routeHelper';

import {
  Contact,
  Login,
  OTPConfirmation,
  Home,
  PaymentDetails,
  PaymentMethodDetails,
  LoanOverview,
  PaymentMethodOverview,
  PaymentOverview,
  UserProfile,
  MyLoans,
  MyPaymentMethods,
  MyPayments,
  MyScheduledPayments,
  MyDocuments,
  EFTAgreement,
  MyLoanPaidOff,
  AutopayAuthTerms,
  AutopayActivation,
  SelectPaymentMethod,
  NoMatch404,
} from 'components/pages';
import AuthRoute from './AuthRoute';
import RequireAuth from './PrivateRoute';
import { useAuthenticationSubscriber } from 'hooks';

const RouterContainer: FC = () => {
  const currentUser = useAuthenticationSubscriber();
  const location = useLocation();

  return (
    <Routes>
      <Route
        path={routes.LOGIN}
        element={
          <AuthRoute currentUser={currentUser}>
            <Login />
          </AuthRoute>
        }
      />
      <Route
        path={routes.OTP_CONFIRMATION}
        element={
          <AuthRoute currentUser={currentUser}>
            <OTPConfirmation />
          </AuthRoute>
        }
      />

      <Route path={routes.ROOT} element={<Navigate to={routes.HOME} replace />} />

      {/* TODO: How to pass Location to RequireAuth??? */}
      <Route
        path={routes.HOME}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <Home />
          </RequireAuth>
        }
      />
      <Route path={routes.CONTACT} element={<Contact />} />
      <Route
        path={routes.PAYMENT_DETAILS}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <PaymentDetails />
          </RequireAuth>
        }
      />
      <Route
        path={routes.PAYMENT_METHOD_DETAILS}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <PaymentMethodDetails from={location} />
          </RequireAuth>
        }
      />
      <Route
        path={routes.LOAN_OVERVIEW}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <LoanOverview />
          </RequireAuth>
        }
      />
      <Route
        path={routes.LOAN_OVERVIEW_BY_UNIQUE_ID}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <LoanOverview />
          </RequireAuth>
        }
      />
      <Route
        path={routes.LOAN_OVERVIEW_BY_UNIQUE_ID_SCHEDULED}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <LoanOverview />
          </RequireAuth>
        }
      />
      <Route
        path={routes.PAYMENT_METHOD_OVERVIEW}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <PaymentMethodOverview />
          </RequireAuth>
        }
      />
      <Route
        path={routes.PAYMENT_OVERVIEW}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <PaymentOverview />
          </RequireAuth>
        }
      />
      <Route
        path={routes.USER_PROFILE}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <UserProfile />
          </RequireAuth>
        }
      />
      <Route
        path={routes.MY_LOANS}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <MyLoans />
          </RequireAuth>
        }
      />
      <Route
        path={routes.LOAN_PAID_OFF}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <MyLoanPaidOff />
          </RequireAuth>
        }
      />
      <Route
        path={routes.MY_PAYMENT_METHODS}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <MyPaymentMethods />
          </RequireAuth>
        }
      />
      <Route
        path={routes.MY_PAYMENTS}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <MyPayments />
          </RequireAuth>
        }
      />
      <Route
        path={routes.MY_SCHEDULED_PAYMENTS}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <MyScheduledPayments />
          </RequireAuth>
        }
      />
      <Route
        path={routes.MY_DOCUMENTS}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <MyDocuments />
          </RequireAuth>
        }
      />
      <Route
        path={routes.EFT_AGREEMENT}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <EFTAgreement />
          </RequireAuth>
        }
      />
      <Route
        path={routes.AUTOPAY_AUTH_TERMS}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <AutopayAuthTerms />
          </RequireAuth>
        }
      />
      <Route
        path={routes.AUTOPAY_ACTIVATION}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <AutopayActivation />
          </RequireAuth>
        }
      />
      <Route
        path={routes.SELECT_PAYMENT_METHOD}
        element={
          <RequireAuth redirectTo={routes.LOGIN} currentUser={currentUser}>
            <SelectPaymentMethod />
          </RequireAuth>
        }
      />
      <Route element={<NoMatch404 />} />
    </Routes>
  );
};

export default RouterContainer;
