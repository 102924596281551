import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getPaymentMethods } from 'actions/paymentMethod';
import { useAuthenticationSubscriber } from 'hooks';

const usePaymentMethodFetcher = () => {
  const dispatch = useDispatch();
  const currentUser = useAuthenticationSubscriber();

  const fetchPaymentMethods = useCallback(() => dispatch(getPaymentMethods()), [dispatch]);

  useEffect(() => {
    if (currentUser.scratchBorrowerId) {
      fetchPaymentMethods();
    }
  }, [currentUser, fetchPaymentMethods]);

  return { fetchPaymentMethods };
};

export default usePaymentMethodFetcher;
