import { setSelectedLoanId } from 'actions/loan';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as loanSelectors from 'selectors/loan';
import { CLIENT_STORAGE_KEY } from 'utils/constants';
import useClientStorage from './useClientStorage';

const useSelectedLoan = () => {
  const dispatch = useDispatch();
  const { uniqueId: paramsSelectedLoanId } = useParams();
  const { getItem: getSelectedLoanIdStorageItem, handleSetStorageItem } = useClientStorage(
    'local',
    CLIENT_STORAGE_KEY.SELECTED_LOAN_ID,
  );
  const { selectedLoan } = useSelector((state: IRootState) => {
    const selectedLoanIdStorageItem = getSelectedLoanIdStorageItem();
    const selectedLoanId =
      (typeof paramsSelectedLoanId === 'string' && paramsSelectedLoanId) ||
      (typeof selectedLoanIdStorageItem === 'string' && selectedLoanIdStorageItem) ||
      null;

    if (selectedLoanId && !state.loan.selectedLoanId) {
      dispatch(setSelectedLoanId(selectedLoanId));
    }

    return { selectedLoan: loanSelectors.selectedLoanSelector(state) };
  });

  const selectedLoanId = selectedLoan && selectedLoan.id;
  useEffect(() => {
    if (selectedLoanId) {
      // override local storage selected loan id
      handleSetStorageItem(selectedLoanId);
    }
  }, [handleSetStorageItem, selectedLoanId]);

  return selectedLoan;
};

export default useSelectedLoan;
