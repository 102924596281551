import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useEffect, useState } from 'react';
import { setSelectedLoanId, toggleAutopay } from 'actions/loan';
import { showNotification } from 'utils/notification';
import * as loanSelectors from 'selectors/loan';
import * as paymentMethodSelectors from 'selectors/paymentMethod';
import * as primaryPaymentMethodInfoSelector from 'selectors/primaryPaymentMethodInfo';
import { getUseablePaymentMethodsByLoan } from '../utils/loan';
import { routes } from 'utils/routeHelper';
import { useNavigate } from 'react-router-dom';

const useAutopayToggle = (loan: ILoan) => {
  const [isOpenDisableAutopayDialog, setIsOpenDisableAutopayDialog] = useState(false);
  const [isOpenEnableAutopayDialog, setIsOpenEnableAutopayDialog] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loanId = loan.id;
  const {
    isAutopayEnabled,
    isTogglingAutopay,
    isSettingPrimaryPaymentMethod,
    isTake5Loan,
    isPendingLoan,
    isFetchingPaymentMethods,
    useablePaymentMethodsByLoan,
    isAddPrimaryPaymentMethod,
    primaryPaymentMethodInfoLoanId,
  } = useSelector((state: IRootState) => {
    const payixPaymentMethods = paymentMethodSelectors.payixPaymentMethodsSelector(state);
    const stripePaymentMethods = paymentMethodSelectors.stripePaymentMethodsSelector(state);
    const useablePaymentMethodsByLoan = getUseablePaymentMethodsByLoan({
      loan,
      payixPaymentMethods,
      stripePaymentMethods,
    });

    return {
      isAutopayEnabled: loanSelectors.isAutopayEnabledSelector(loanId)(state),
      isTogglingAutopay: loanSelectors.isTogglingAutopaySelector(loanId)(state),
      isSettingPrimaryPaymentMethod: loanSelectors.isSettingPrimaryPaymentMethodSelector(loanId)(state),
      isTake5Loan: loanSelectors.isTake5LoanSelector(loanId)(state),
      isPendingLoan: loanSelectors.isPendingLoanSelector(loanId)(state),
      isFetchingPaymentMethods: paymentMethodSelectors.isFetchingSelector(state),
      useablePaymentMethodsByLoan,
      isAddPrimaryPaymentMethod: primaryPaymentMethodInfoSelector.isAddPrimaryPaymentMethodSelector(state),
      primaryPaymentMethodInfoLoanId: primaryPaymentMethodInfoSelector.primaryPaymentMethodInfoLoanIdSelector(state),
    };
  });

  const isProcessingWithAutopay = isTogglingAutopay || isSettingPrimaryPaymentMethod;
  useEffect(() => {
    if (isAddPrimaryPaymentMethod && primaryPaymentMethodInfoLoanId === loanId) {
      setIsOpenEnableAutopayDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnCloseEnableAutopayDialog = () => setIsOpenEnableAutopayDialog(false);
  const handleOnCloseDisableAutopayDialog = () => setIsOpenDisableAutopayDialog(false);

  const handleOnToggleAutopay = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (loan.restructured === 'src') return;
    const expectToEnableAutopay = !isAutopayEnabled && checked;
    const expectToDisableAutopay = isAutopayEnabled && !checked;

    if (isPendingLoan) {
      showNotification(`You can't ${expectToEnableAutopay ? 'enable' : 'disable'} Autopay on a pending payment plan`, {
        variant: 'info',
      });
      return;
    }

    if (isTake5Loan) {
      showNotification(`You can't ${expectToEnableAutopay ? 'enable' : 'disable'} Autopay on a Take5 payment plan`, {
        variant: 'info',
      });
      return;
    }

    if (expectToDisableAutopay) {
      setIsOpenDisableAutopayDialog(true);
      return;
    }

    dispatch(setSelectedLoanId(loan.id));
    navigate(routes.AUTOPAY_ACTIVATION);
  };

  const handleOnDisableAutopay = () => {
    dispatch(
      toggleAutopay({
        data: {
          loanId,
          isEnabled: false,
        },
      }),
    );
    setIsOpenDisableAutopayDialog(false);
  };

  return {
    isAutopayEnabled,
    isProcessingWithAutopay,
    isOpenEnableAutopayDialog,
    setIsOpenEnableAutopayDialog,
    handleOnCloseEnableAutopayDialog,
    isOpenDisableAutopayDialog,
    setIsOpenDisableAutopayDialog,
    handleOnToggleAutopay,
    // Used with disable autopay dialog
    handleOnCloseDisableAutopayDialog,
    handleOnDisableAutopay,
    // Used with enable autopay dialog
    isFetchingPaymentMethods,
    useablePaymentMethodsByLoan,
  };
};

export default useAutopayToggle;
