import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router';

import { capitalize } from 'lodash';
import { Button } from '@mui/material';

import { deletePaymentMethod } from 'actions/paymentMethod';
import { selectedPaymentMethodSelector, isDeletingSelector } from 'selectors/paymentMethod';
import { routes } from 'utils/routeHelper';
import { showNotification } from 'utils/notification';
import { formatHiddenDigitsWithLast4, formatDate, DATE_FORMATS } from 'utils/format';
import { PAYMENT_METHOD_TYPES, DEFAULT_VALUE_MISSING_FIELDS } from 'utils/constants';

import { Header } from 'components/layouts';
import { Col, PaymentMethodLogo, Dialog } from 'components/widgets';

import './PaymentMethodOverview.scss';
import { useNavigate } from 'react-router-dom';

const OverviewHolderName = ({ label, value }: { label: string; value: string }) => (
  <div styleName="overview-holdername">
    <div styleName="label">{label}</div>
    <div styleName="value">{value}</div>
  </div>
);

const OverviewItem = ({
  label,
  value,
  cardBrand = undefined,
  isACHOrPAD = false,
}: {
  label: string;
  value: string;
  cardBrand?: string;
  isACHOrPAD?: boolean;
}) => {
  return (
    <div key={label} styleName="item">
      <div styleName="label">{label}</div>
      <div styleName="value">
        {!!cardBrand && <PaymentMethodLogo isCard cardBrand={cardBrand} />}
        {!!isACHOrPAD && <PaymentMethodLogo isCard={false} />}
        {value}
      </div>
    </div>
  );
};

export const CardOverview = ({
  holderName,
  last4,
  card,
}: {
  holderName: string;
  last4: string;
  card: IPaymentMethodCard;
}) => {
  const { cardBrand, cardExpDate, billingZip } = card;
  const formattedLast4 = formatHiddenDigitsWithLast4(PAYMENT_METHOD_TYPES.CARD, last4);
  const expiratedDate = formatDate(cardExpDate, { outputFormat: DATE_FORMATS.MM_YY });
  return (
    <>
      <OverviewHolderName label="Card holder name" value={holderName} />
      <div styleName="block">
        <OverviewItem label="Card number" value={formattedLast4} cardBrand={cardBrand} />
        <OverviewItem label="Expiration date" value={expiratedDate} />
        <OverviewItem label="Billing zip" value={billingZip} />
      </div>
    </>
  );
};

export const ACHOverview = ({
  holderName,
  last4,
  ach,
}: {
  holderName: string;
  last4: string;
  ach: IPaymentMethodACH;
}) => {
  const { bankName, accountType, bankRoutingNumber } = ach;
  const formattedLast4 = formatHiddenDigitsWithLast4(PAYMENT_METHOD_TYPES.ACH, last4);
  const bankRoutingNumberValue = bankRoutingNumber || DEFAULT_VALUE_MISSING_FIELDS;
  const accountTypeValue = accountType ? capitalize(accountType) : DEFAULT_VALUE_MISSING_FIELDS;

  return (
    <>
      <OverviewHolderName label="Bank account holder name" value={holderName} />
      <div styleName="block">
        <OverviewItem label="Bank name" value={bankName} />
        <OverviewItem label="Routing number (ABA)" value={bankRoutingNumberValue} />
        <OverviewItem label="Account number" value={formattedLast4} isACHOrPAD />
        <OverviewItem label="Account type" value={accountTypeValue} />
      </div>
    </>
  );
};

export const PADOverview = ({
  holderName,
  last4,
  pad,
}: {
  holderName: string;
  last4: string;
  pad: IPaymentMethodPAD;
}) => {
  const { bankName, bankNumber, branchNumber } = pad;
  const formattedLast4 = formatHiddenDigitsWithLast4(PAYMENT_METHOD_TYPES.PAD, last4);
  const bankNumberValue = bankNumber || DEFAULT_VALUE_MISSING_FIELDS;
  const branchNumberValue = branchNumber || DEFAULT_VALUE_MISSING_FIELDS;
  return (
    <>
      <OverviewHolderName label="Account holder name" value={holderName} />
      <div styleName="block">
        <OverviewItem label="Bank name" value={bankName} />
        <OverviewItem label="Bank number" value={bankNumberValue} />
        <OverviewItem label="Account number" value={formattedLast4} isACHOrPAD />
        <OverviewItem label="Branch number" value={branchNumberValue} />
      </div>
    </>
  );
};

const PaymentMethodOverview = () => {
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const { selectedMethod, isDeleting } = useSelector((state: IRootState) => ({
    selectedMethod: selectedPaymentMethodSelector(state),
    isDeleting: isDeletingSelector(state),
  }));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!selectedMethod) return <Navigate to={routes.MY_PAYMENT_METHODS} replace />;

  const { holderName, last4, paymentType, ach, card, pad } = selectedMethod;

  const onOpenDeleteMethodDialog = () => {
    if (selectedMethod.isUsedForDownPayment === true) {
      showNotification("You can't delete a card used during Take5 application", { variant: 'info' });
    } else {
      setIsOpenDialog(true);
    }
  };

  const onCloseDeleteMethodDialog = () => {
    if (isDeleting) return;
    setIsOpenDialog(false);
  };

  const onDeleteMethod = () => {
    dispatch(
      deletePaymentMethod({
        data: {
          tokenId: selectedMethod.id,
        },
        onSuccess: () => {
          onCloseDeleteMethodDialog();
          showNotification('Your payment method has been deleted', { variant: 'success' });
          navigate(-1);
        },
        onError: () => {
          onCloseDeleteMethodDialog();
          showNotification('There was an issue to delete your payment method', { variant: 'error' });
        },
      }),
    );
  };

  const isCard = paymentType === PAYMENT_METHOD_TYPES.CARD && card;
  const isACH = paymentType === PAYMENT_METHOD_TYPES.ACH && ach;
  const isPAD = paymentType === PAYMENT_METHOD_TYPES.PAD && pad;

  return (
    <div styleName="wrapper">
      <Header />
      <Col xs={12} sm={6} md={4} styleName="content-wrapper">
        {isCard && <CardOverview holderName={holderName} last4={last4} card={card!} />}
        {isACH && <ACHOverview holderName={holderName} last4={last4} ach={ach!} />}
        {isPAD && <PADOverview holderName={holderName} last4={last4} pad={pad!} />}
        <Button type="button" styleName="btn delete-method" onClick={onOpenDeleteMethodDialog}>
          Delete payment method
        </Button>
      </Col>
      <Dialog
        isOpen={isOpenDialog}
        onClose={onCloseDeleteMethodDialog}
        title="Are you sure you want to delete this Payment method?"
        actions={[
          {
            text: 'Cancel',
            type: 'negative',
            onClick: onCloseDeleteMethodDialog,
          },
          {
            text: isDeleting ? 'Deleting...' : 'Yes, Delete',
            disabled: isDeleting,
            onClick: onDeleteMethod,
          },
        ]}
      >
        If you proceed, you will no longer be able to make a payment using this payment method.
      </Dialog>
    </div>
  );
};

export default PaymentMethodOverview;
