import { useDispatch } from 'react-redux';

import { toggleAutopay } from 'actions/loan';

import SetPrimaryPaymentMethodDialog, { Props } from './SetPrimaryPaymentMethodDialog';

const EnableAutopayDialog = (props: Props) => {
  const { loanId } = props;
  const dispatch = useDispatch();

  const handleOnSetPrimaryPaymentMethod = (tokenId: string) => {
    dispatch(
      toggleAutopay({
        data: {
          loanId,
          tokenId,
          isEnabled: true,
        },
      }),
    );
  };

  return <SetPrimaryPaymentMethodDialog {...props} onSetPrimaryPaymentMethod={handleOnSetPrimaryPaymentMethod} />;
};

export default EnableAutopayDialog;
