import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { scroller } from 'react-scroll';

import { routes } from 'utils/routeHelper';
import { isCordovaApp } from 'utils/constants';

import { usePaymentFetcher, useLoanAndScheduledPaymentFetcher } from 'hooks';
import { setSelectedLoanId } from 'actions/loan';
import * as loanSelectors from 'selectors/loan';

import { HomePageLayout } from 'components/layouts';
import { Col, PaymentPlanList, LoadingSpinner } from 'components/widgets';
import { IconScratchpayHeart } from 'assets/icons';

import './Home.scss';
import { FindPlanButton } from 'components/widgets';
import { useNavigate, useSearchParams } from 'react-router-dom';

const SCROLLING_DURATION_IN_MILISECONDS = 500;

const Home = () => {
  usePaymentFetcher();
  useLoanAndScheduledPaymentFetcher();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  const {
    isSynchronizing,
    isFetched,
    loanCount,
    errorLoans,
    pendingLoans,
    paidoffLoans,
    payableLoans,
    selectedLoanId,
    hasDelinquentLoan,
  } = useSelector((state: IRootState) => ({
    isSynchronizing: loanSelectors.isSynchronizingSelector(state),
    isFetched: loanSelectors.isFetchedSelector(state),
    loanCount: loanSelectors.loanCountSelector(state),
    errorLoans: loanSelectors.errorLoansSelector(state),
    pendingLoans: loanSelectors.pendingLoansSelector(state),
    paidoffLoans: loanSelectors.paidoffLoansSelector(state),
    payableLoans: loanSelectors.payableLoansSelector(state),
    selectedLoanId: loanSelectors.selectedLoanIdSelector(state),
    hasDelinquentLoan: loanSelectors.hasDelinquentLoan(state),
  }));

  useEffect(() => {
    if (selectedLoanId) {
      scroller.scrollTo(selectedLoanId, {
        duration: SCROLLING_DURATION_IN_MILISECONDS,
        smooth: 'easeOutQuart',
        offset: -80,
      });
    }
    dispatch(setSelectedLoanId(''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasLoans = !!loanCount;
  const hasNoLoan = isFetched && !hasLoans;
  const loaderVisible = isSynchronizing && !hasLoans;

  const handleOnClickPaymentPlanItem = (loanId: string) => {
    dispatch(setSelectedLoanId(loanId));
    localStorage.setItem('selectedLoanId', loanId);
    navigate(routes.LOAN_OVERVIEW);
  };

  if (isCordovaApp) {
    window.cordova.plugins.firebase.dynamiclinks.onDynamicLink((data: any) => {
      const url = new URL(data.deepLink);
      setSearchParams(url.search);
      return navigate(url.pathname);
    });
  }

  return (
    <Col xs={12} sm={6} md={6}>
      <HomePageLayout>
        <div styleName="wrapper">
          <IconScratchpayHeart styleName="icon heart" />
          {loaderVisible && <LoadingSpinner />}
          {hasLoans && (
            <>
              {!hasDelinquentLoan && (
                <div styleName="btn-find-plan">
                  <FindPlanButton align="right" label="New plan" />
                </div>
              )}
              <PaymentPlanList loans={errorLoans} onClickPaymentPlanItem={handleOnClickPaymentPlanItem} />
              <PaymentPlanList
                title={`Pending Plans (${pendingLoans.length})`}
                loans={pendingLoans}
                onClickPaymentPlanItem={handleOnClickPaymentPlanItem}
              />
              <PaymentPlanList
                title={`Active Plans (${payableLoans.length})`}
                loans={payableLoans}
                onClickPaymentPlanItem={handleOnClickPaymentPlanItem}
              />
              <PaymentPlanList
                title={`Paid off plans (${paidoffLoans.length})`}
                loans={paidoffLoans}
                onClickPaymentPlanItem={handleOnClickPaymentPlanItem}
              />
            </>
          )}
          {hasNoLoan && (
            <>
              <p styleName="explanation">
                We cannot find any payment plan assiciated with your account. To find the payment plan that suits you
                best & apply in just a few steps, tap the button below.
              </p>
              <FindPlanButton align="center" color="colored" label="Find a payment plan" />
            </>
          )}
        </div>
      </HomePageLayout>
    </Col>
  );
};

export default Home;
