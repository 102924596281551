import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import AddIcon from '@mui/icons-material/Add';

import { useSelectedLoan } from 'hooks';
import { selectPaymentMethod } from 'actions/paymentMethod';
import { routes } from 'utils/routeHelper';
import * as paymentMethodSelectors from 'selectors/paymentMethod';
import * as appSelectors from 'selectors/app';
import { getUseablePaymentMethodsByLoan } from 'utils/loan';

import { Header, BodyLayout, Footer } from 'components/layouts';
import { Col, PaymentMethodDropdown, UserDecisionWrapper } from 'components/widgets';

import './SelectPaymentMethod.scss';

export const SelectPaymentMethod = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedLoan = useSelectedLoan();
  const { isFetching, selectedPaymentMethod, stripePaymentMethods, payixPaymentMethods, returnUrl } = useSelector(
    (state: IRootState) => {
      const isFetchingMethods = paymentMethodSelectors.isFetchingSelector(state);
      return {
        isFetching: isFetchingMethods,
        selectedPaymentMethod: paymentMethodSelectors.selectedPaymentMethodSelector(state),
        stripePaymentMethods: paymentMethodSelectors.stripePaymentMethodsSelector(state),
        payixPaymentMethods: paymentMethodSelectors.payixPaymentMethodsSelector(state),
        returnUrl: appSelectors.returnUrlSelector(state),
      };
    },
  );

  if (!selectedLoan) return <Navigate to={routes.HOME} replace />;

  const isAddPaymentMethodButtonDisabled = isFetching;
  const useablePaymentMethods = getUseablePaymentMethodsByLoan({
    loan: selectedLoan,
    stripePaymentMethods,
    payixPaymentMethods,
  });
  const hasPaymentMethods = !!useablePaymentMethods.length;
  if (!hasPaymentMethods) return <Navigate to={routes.PAYMENT_METHOD_DETAILS} replace />;

  const defaultPaymentMethodId = useablePaymentMethods[0].id;
  const paymentMethodId = selectedPaymentMethod ? selectedPaymentMethod.id : defaultPaymentMethodId;

  const handleOnChangePaymentMethod = (event: ChangeEvent<HTMLInputElement>) => {
    const tokenId = event.target.value;
    if (!tokenId) return;

    dispatch(selectPaymentMethod(tokenId));
  };

  return (
    <Col xs={12} styleName="wrapper">
      <Header styleOptions={{ isSticky: false, isLight: true }} />
      <BodyLayout heading="Add Payment" subHeading={`Loan ID ${selectedLoan.id}`} runLoadingAnimation={isFetching}>
        <p styleName="card-title">Payment Type:</p>
        <Col xs={12} styleName="payment-method">
          <PaymentMethodDropdown
            label={null}
            disabled={isFetching}
            isFetching={isFetching}
            paymentMethods={useablePaymentMethods}
            value={paymentMethodId}
            onChange={handleOnChangePaymentMethod}
            showAddPaymentMethod={false}
          />
        </Col>
        <div styleName={classNames('add-payment', isAddPaymentMethodButtonDisabled && 'add-payment-disabled')}>
          <Link to={routes.PAYMENT_METHOD_DETAILS}>
            <AddIcon fontSize="small" />
            <span>Add payment</span>
          </Link>
        </div>
        {!!returnUrl && <UserDecisionWrapper buttonText="Next" buttonProps={{ onClick: () => navigate(returnUrl) }} />}
      </BodyLayout>
      <Footer />
    </Col>
  );
};

export default SelectPaymentMethod;
