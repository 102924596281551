import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PhoneOutlined as IconPhone,
  MailOutlineOutlined as IconMail,
  RoomOutlined as IconMapPin,
  CakeOutlined as IconBirthDate,
} from '@mui/icons-material';
import { Button } from '@mui/material';
import { capitalize } from 'lodash';
import { FixedBottom } from 'react-fixed-bottom';
import { getName } from 'country-list';

import { useBorrowerFetcher } from 'hooks';
import { logout } from 'actions/user';
import { borrowerSelector, isRenewingBorrowerSelector } from 'selectors/user';
import { formatPhoneNumber, formatDate } from 'utils/format';
import { AREA_CODE_US_CANADA } from 'utils/constants';

import { Header } from 'components/layouts';
import { Col, Dialog, LoadingSpinner } from 'components/widgets';

import { IconEmptyProfilePhoto } from 'assets/icons';

import './UserProfile.scss';

const UserProfile = () => {
  useBorrowerFetcher();
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
  const { isRenewingBorrower, borrower } = useSelector((state: IRootState) => ({
    isRenewingBorrower: isRenewingBorrowerSelector(state),
    borrower: borrowerSelector(state),
  }));
  const dispatch = useDispatch();

  const onOpenLogoutDialog = () => setIsOpenDialog(true);

  const onCloseLogoutDialog = () => setIsOpenDialog(false);

  const onLogout = async () => {
    setIsLoggingOut(true);
    dispatch(
      logout({
        onError: () => setIsLoggingOut(false),
      }),
    );
  };

  const renderContact = () => {
    if (!borrower) {
      return null;
    }

    const normalizedPhoneNumber = borrower.phoneNumber.startsWith('+')
      ? borrower.phoneNumber
      : `+${borrower.phoneNumber}`;
    const phoneNumber = formatPhoneNumber(AREA_CODE_US_CANADA, normalizedPhoneNumber.replace(AREA_CODE_US_CANADA, ''));
    const fullName = [borrower.firstName, borrower.lastName]
      .filter((name) => name)
      .map(capitalize)
      .join(' ');
    const fullAddress = [borrower.address, borrower.address2].join(' ').trim();
    const cityStatePostalCode = [borrower.city, borrower.state, borrower.postalCode].join(' ').trim();
    const countryName = (getName(borrower.countryISO) || '').toUpperCase();
    return (
      <Fragment>
        <div styleName="user-profile">
          <IconEmptyProfilePhoto styleName="icon-photo" />
          <div styleName="fullname">{fullName}</div>
        </div>
        <div styleName="contact-info-wrapper">
          {borrower.birthdate && (
            <div styleName="contact-info">
              <IconBirthDate styleName="icon" />
              <div styleName="info">{formatDate(borrower.birthdate)}</div>
            </div>
          )}
          <div styleName="contact-info">
            <IconPhone styleName="icon" />
            <a styleName="info">{phoneNumber}</a>
          </div>
          <div styleName="contact-info">
            <IconMail styleName="icon" />
            <a styleName="info">{borrower.email}</a>
          </div>
          <div styleName="contact-info">
            <IconMapPin styleName="icon" />
            <div styleName="info">
              <span>{fullAddress}</span>
              <br />
              <span>{cityStatePostalCode}</span>
              {countryName && (
                <>
                  <br />
                  <span>{countryName}</span>
                </>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Col xs={12} sm={6} md={6} styleName="wrapper">
      <div styleName="content-action-wrapper">
        <div styleName="content-wrapper">
          {/* <Header title="User profile" onClose={onClose} /> */}
          <Header />
          {renderContact()}
          {isRenewingBorrower && <LoadingSpinner />}
        </div>
        <FixedBottom offset={28}>
          <Button type="button" styleName="btn log-out" onClick={onOpenLogoutDialog}>
            Logout
          </Button>
        </FixedBottom>
      </div>
      <Dialog
        isOpen={isOpenDialog}
        onClose={onCloseLogoutDialog}
        title="Are you sure you want to logout?"
        actions={[
          {
            text: 'Cancel',
            type: 'negative',
            onClick: onCloseLogoutDialog,
          },
          {
            text: 'Log me out',
            onClick: onLogout,
            disabled: isLoggingOut,
          },
        ]}
      >
        You will have to go through secure login flow to access your borrower portal again.
      </Dialog>
    </Col>
  );
};

export default UserProfile;
