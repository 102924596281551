import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { renewLoans } from 'actions/loan';
import { useAuthenticationSubscriber } from 'hooks';

const useLoanAndScheduledPaymentFetcher = () => {
  const dispatch = useDispatch();
  const currentUser = useAuthenticationSubscriber();

  const fetchLoansAndScheduledPayments = useCallback(() => dispatch(renewLoans()), [dispatch]);

  useEffect(() => {
    if (currentUser.scratchBorrowerId) {
      fetchLoansAndScheduledPayments();
    }
  }, [currentUser, fetchLoansAndScheduledPayments]);

  return { fetchLoansAndScheduledPayments };
};

export default useLoanAndScheduledPaymentFetcher;
