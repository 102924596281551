import { createRoot } from 'react-dom/client';

import { isCordovaApp, isCordovaIOSApp } from 'utils/constants';
import { intializeSentry } from 'utils/sentry';

import Root from './Root';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initializeFirebase } from 'utils/firebase';

const startApp = () => {
  intializeSentry({ environment: process.env.DEPLOYMENT_ENV || '' });
  initializeFirebase();

  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(<Root />);
};

if (isCordovaApp) {
  document.addEventListener('deviceready', startApp, false);
  if (isCordovaIOSApp) {
    const iosViewportMetaTag = 'user-scalable=no, initial-scale=1, width=device-width, viewport-fit=cover';
    const metaViewport = document.createElement('meta');
    metaViewport.httpEquiv = 'viewport';
    metaViewport.content = iosViewportMetaTag;
    document.head.appendChild(metaViewport);
  }
} else {
  startApp();
}

serviceWorkerRegistration.unregister();
