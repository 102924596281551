import { useEffect } from 'react';
import { onLoad } from 'utils/keyboardWrapper';

interface IProps {
  children?: any;
}

const KeyboardWrapper = (props: IProps) => {
  useEffect(() => {
    onLoad();
  }, []);

  return <div className="keyboard-wrapper">{props.children}</div>;
};

export default KeyboardWrapper;
