import { useEffect, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  addPaymentMethod,
  getPaymentMethods,
  IAddPaymentMethodRegularData,
  IAddPaymentMethodStripeACHData,
  selectPaymentMethod,
} from 'actions/paymentMethod';
import { getPlaidLinkToken } from 'actions/paymentMethod';
import { borrowerSelector } from 'selectors/user';
import * as loanSelectors from 'selectors/loan';
import * as paymentMethodSelectors from 'selectors/paymentMethod';
import { isAddPrimaryPaymentMethodSelector } from 'selectors/primaryPaymentMethodInfo';
import { routes } from 'utils/routeHelper';
import { showNotification } from 'utils/notification';

import { Header } from 'components/layouts';
import { Col, StripeCardForm, PlaidACHForm, PayixIFrame, LoadingSpinner, LoanDropdown } from 'components/widgets';

import './PaymentMethodDetails.scss';
import { Navigate } from 'react-router';
import { setPrimaryPaymentMethodInfo } from 'actions/primayPaymentMethodInfo';
import { Location, useNavigate } from 'react-router-dom';

const TAB_INDEX = {
  STRIPE_CARD: 0,
  PLAID_ACH: 1,
};

export const PaymentMethodDetails = ({ from }: { from: Location }) => {
  const [selectedLoanId, setSelectedLoanId] = useState<Undefinable<string>>(undefined);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(TAB_INDEX.STRIPE_CARD);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isFetched,
    isFetching,
    loans,
    loanById,
    initialSelectedLoanId,
    borrower,
    isAdding,
    plaidLinkToken,
    isFetchingPlaidLinkToken,
    isAddPrimaryPaymentMethod,
  } = useSelector((state: IRootState) => ({
    isFetched: loanSelectors.isFetchedSelector(state),
    isFetching: loanSelectors.isFetchingSelector(state),
    loans: loanSelectors.loansSelector(state),
    loanById: loanSelectors.loanByIdSelector(state),
    initialSelectedLoanId:
      loanSelectors.selectedLoanIdSelector(state) || loanSelectors.latestPayableLoanIdSelector(state),
    borrower: borrowerSelector(state),
    isAdding: paymentMethodSelectors.isAddingSelector(state),
    plaidLinkToken: paymentMethodSelectors.plaidLinkTokenSelector(state),
    isFetchingPlaidLinkToken: paymentMethodSelectors.isFetchingPlaidLinkTokenSelector(state),
    isAddPrimaryPaymentMethod: isAddPrimaryPaymentMethodSelector(state),
  }));
  const returnedUrl = from ? from.pathname : routes.MY_PAYMENT_METHODS;
  const redirectedUrl = from ? from.pathname : routes.HOME;

  useEffect(() => {
    dispatch(getPlaidLinkToken());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialSelectedLoanId) {
      setSelectedLoanId(initialSelectedLoanId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddedSuccess = (result: IAddPaymentMethodRegularData | IAddPaymentMethodStripeACHData) => {
    dispatch(
      addPaymentMethod({
        data: result,
        onSuccess: (tokenId) => {
          showNotification('Your payment method has been added', { variant: 'success' });
          if (isAddPrimaryPaymentMethod) {
            dispatch(
              setPrimaryPaymentMethodInfo({
                loanId: selectedLoanId!,
                tokenId,
              }),
            );
          }
          dispatch(getPaymentMethods());
          dispatch(selectPaymentMethod(tokenId));
          navigate(returnedUrl, { replace: true });
        },
        onError: () => {
          showNotification('Your payment method was not added', { variant: 'error' });
          navigate(returnedUrl, { replace: true });
        },
      }),
    );
  };

  const onAddedError = (error: any) => {
    showNotification(`${error.message}. Please try again`, { variant: 'error' });
  };

  const switchStripeTabs = (nextActiveTabIndex: number) => {
    switch (nextActiveTabIndex) {
      case TAB_INDEX.PLAID_ACH: {
        if (!plaidLinkToken) return;
        return setActiveTabIndex(nextActiveTabIndex);
      }
      case TAB_INDEX.STRIPE_CARD:
      default:
        return setActiveTabIndex(nextActiveTabIndex);
    }
  };

  const renderStripePlaidForms = () => {
    if (isFetchingPlaidLinkToken) {
      return <LoadingSpinner />;
    }
    const isActiveCardTab = activeTabIndex === TAB_INDEX.STRIPE_CARD;
    const isActiveACHTab = activeTabIndex === TAB_INDEX.PLAID_ACH;
    return (
      <>
        <ul styleName="list tabs">
          <li
            styleName="list-item tab"
            data-tab-active={isActiveCardTab}
            onClick={() => switchStripeTabs(TAB_INDEX.STRIPE_CARD)}
          >
            CARD
          </li>
          <li
            styleName="list-item tab"
            data-tab-active={isActiveACHTab}
            data-disabled={!plaidLinkToken}
            onClick={() => switchStripeTabs(TAB_INDEX.PLAID_ACH)}
          >
            <PlaidACHForm
              token={plaidLinkToken}
              onExit={() => setActiveTabIndex(TAB_INDEX.STRIPE_CARD)}
              onAddedSuccess={onAddedSuccess}
            />
          </li>
        </ul>
        {isActiveCardTab && (
          <StripeCardForm isAdding={isAdding} onAddedSuccess={onAddedSuccess} onAddedError={onAddedError} />
        )}
      </>
    );
  };

  const handleOnChangeLoan = (event: ChangeEvent<HTMLInputElement>) => {
    const nextLoanId = event.target.value;
    setSelectedLoanId(nextLoanId);
  };

  if (!loans.length && isFetched) {
    return <Navigate to={redirectedUrl} replace />;
  }

  const currentSelectedLoan = selectedLoanId ? loanById[selectedLoanId] : null;
  const isStripeRendered = !!currentSelectedLoan && currentSelectedLoan.isStripeEnabled;

  return (
    <div styleName={classNames('wrapper', { loading: isFetching })}>
      <Col xs={12} sm={6} md={4}>
        <Header />
        {isFetching ? (
          <LoadingSpinner />
        ) : (
          <>
            <div styleName="loan-note">
              <p>Select the plan for which you’d like to add a payment method</p>
              <p>
                <span>Note:</span>&nbsp;Avoid a convenience fee with ACH payments.
              </p>
            </div>
            <LoanDropdown loans={loans} value={selectedLoanId || ''} onChange={handleOnChangeLoan} />
            <div styleName={classNames('wrapper-iframe-form', { payix: currentSelectedLoan && !isStripeRendered })}>
              {currentSelectedLoan ? (
                isStripeRendered ? (
                  renderStripePlaidForms()
                ) : (
                  <PayixIFrame
                    borrower={borrower}
                    loan={currentSelectedLoan!}
                    isAdding={isAdding}
                    onAddedSuccess={onAddedSuccess}
                  />
                )
              ) : null}
            </div>
          </>
        )}
      </Col>
    </div>
  );
};

export default PaymentMethodDetails;
