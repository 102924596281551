import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import { List, ListItem } from '@mui/material';

import { selectPayment } from 'actions/payment';
import { isFetchedPaymentsSelector, isFetchingPaymentsSelector, loanPaymentsSelector } from 'selectors/payment';
import { formatDate, formatAmount } from 'utils/format';
import { routes } from 'utils/routeHelper';

import { Col, LoadingSpinner, IconPaymentMethod } from 'components/widgets';

import './LoanOverviewTabStatements.scss';
import { useNavigate } from 'react-router-dom';

interface IProps {
  loanId: string;
}

const LoanOverviewTabStatements = (props: IProps) => {
  const { loanId } = props;
  const { isFetched, isFetching, loanPayments } = useSelector((state: IRootState) => ({
    isFetched: isFetchedPaymentsSelector(state),
    isFetching: isFetchingPaymentsSelector(state),
    loanPayments: loanPaymentsSelector(loanId)(state),
  }));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasPayments = !!loanPayments.length;
  const hasNoPayment = isFetched && !hasPayments;

  const onClickPaymentItem = (paymentId: number | string) => {
    dispatch(selectPayment(paymentId));
    navigate(routes.PAYMENT_OVERVIEW);
  };

  return (
    <Col xs={12} sm={6} md={4}>
      <div
        styleName={classNames('wrapper', {
          'list-payments': hasPayments,
          'no-data': hasNoPayment,
        })}
      >
        {isFetching && <LoadingSpinner styleName="custom loading" />}
        {hasPayments && (
          <List styleName="list payments">
            {loanPayments.map((payment: IPayment) => (
              <ListItem
                button
                key={payment.id}
                styleName={classNames('list-item payment', { 'has-refund': payment.isRefund })}
                onClick={() => onClickPaymentItem(payment.id)}
              >
                <div styleName="date">{formatDate(payment.transactionDate)}</div>
                <div styleName="amount-wrapper">
                  {payment.isRefund && <div styleName="tag-refund">Refund</div>}
                  <IconPaymentMethod paymentType={payment.paymentMethod.type} />
                  <div styleName="amount">{formatAmount(payment.totalAmount)}</div>
                </div>
              </ListItem>
            ))}
          </List>
        )}
        {hasNoPayment && (
          <span>
            This is where your statements will be located when you begin making payments to your Scratch Plan.
          </span>
        )}
      </div>
    </Col>
  );
};

export default LoanOverviewTabStatements;
