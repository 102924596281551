import { PropsWithChildren } from 'react';

import { Col, IconWrapper, GifAnimationWrapper } from 'components/widgets';
import { IconScratchpayHeart } from 'assets/icons';

import './BodyLayout.scss';

interface IProps {
  heading: string;
  subHeading?: string;
  runLoadingAnimation?: boolean;
}

const BodyLayout = (props: PropsWithChildren<IProps>) => {
  const { children, heading, subHeading, runLoadingAnimation } = props;

  return (
    <div styleName="wrapper">
      <Col xs={12} styleName="col">
        <IconWrapper>
          <IconScratchpayHeart />
        </IconWrapper>
        <h1 styleName="heading">{heading}</h1>
        <h2 styleName="subheading">{subHeading}</h2>
        {children}
        {runLoadingAnimation && <GifAnimationWrapper />}
      </Col>
    </div>
  );
};

export default BodyLayout;
