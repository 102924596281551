import { ChangeEvent, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import * as loanSelectors from 'selectors/loan';
import * as paymentMethodSelectors from 'selectors/paymentMethod';
import * as primaryPaymentMethodInfoSelector from 'selectors/primaryPaymentMethodInfo';
import { setSelectedLoanId, toggleAutopay } from 'actions/loan';
import { showNotification } from 'utils/notification';
import { formatLoanOverviewAmountValue } from 'utils/loan';
import { getUseablePaymentMethodsByLoan } from 'utils/loan';
import { DEFAULT_VALUE_MISSING_FIELDS } from 'utils/constants';
import { formatAmount } from 'utils/format';
import { shortenString } from 'utils/stringHelper';

import { LoadingSpinner } from 'components/widgets';
import AutopayToggle from './AutopayToggle';
import DisableAutopayDialog from './DisableAutopayDialog';
import EnableAutopayDialog from './EnableAutopayDialog';
import SetPrimaryPaymentMethodDialog from './SetPrimaryPaymentMethodDialog';
import CallButton from '../CallButton';

import './PaymentPlanListItem.scss';
import PaymentMethodListItem from '../paymentMethod/PaymentMethodListItem';

import { routes } from 'utils/routeHelper';
import { useNavigate } from 'react-router-dom';

interface IProps {
  loan: ILoan;
  onClick: (loanId: string) => void;
}

const PaymentPlanListItem = ({ loan, onClick }: IProps) => {
  const navigate = useNavigate();
  const loanId = loan.id;
  const {
    isSynchronizingLoans,
    isTogglingAutopay,
    isSettingPrimaryPaymentMethod,
    isPendingLoan,
    isPaidoffLoan,
    isTake5Loan,
    isFetchingPaymentMethods,
    useablePaymentMethodsByLoan,
    isAddPrimaryPaymentMethod,
    primaryPaymentMethodInfoLoanId,
  } = useSelector((state: IRootState) => {
    const payixPaymentMethods = paymentMethodSelectors.payixPaymentMethodsSelector(state);
    const stripePaymentMethods = paymentMethodSelectors.stripePaymentMethodsSelector(state);
    const useablePaymentMethodsByLoan = getUseablePaymentMethodsByLoan({
      loan,
      payixPaymentMethods,
      stripePaymentMethods,
    });
    return {
      isSynchronizingLoans: loanSelectors.isSynchronizingSelector(state),
      isTogglingAutopay: loanSelectors.isTogglingAutopaySelector(loan.id)(state),
      isSettingPrimaryPaymentMethod: loanSelectors.isSettingPrimaryPaymentMethodSelector(loan.id)(state),
      isPendingLoan: loanSelectors.isPendingLoanSelector(loan.id)(state),
      isPaidoffLoan: loanSelectors.isPaidoffLoanSelector(loan.id)(state),
      isTake5Loan: loanSelectors.isTake5LoanSelector(loan.id)(state),
      isFetchingPaymentMethods: paymentMethodSelectors.isFetchingSelector(state),
      useablePaymentMethodsByLoan,
      isAddPrimaryPaymentMethod: primaryPaymentMethodInfoSelector.isAddPrimaryPaymentMethodSelector(state),
      primaryPaymentMethodInfoLoanId: primaryPaymentMethodInfoSelector.primaryPaymentMethodInfoLoanIdSelector(state),
    };
  });
  const [isOpenDisableAutopayDialog, setIsOpenDisableAutopayDialog] = useState(false);
  const [isOpenEnableAutopayDialog, setIsOpenEnableAutopayDialog] = useState(false);
  const [isOpenSetPrimaryPaymentMethodDialog, setIsOpenSetPrimaryPaymentMethodDialog] = useState(false);
  const dispatch = useDispatch();

  const toggleNodeId = `toggle-${loan.id}`;
  const hasAutopayToggle = useMemo(() => !isPaidoffLoan && !isPendingLoan, [isPaidoffLoan, isPendingLoan]);
  const isProcessingWithAutopay = isTogglingAutopay || isSettingPrimaryPaymentMethod;
  const canChangePrimaryPaymentMethod =
    loan.primaryPaymentMethod && loan.hasScheduledPayments && !isProcessingWithAutopay;
  const payoffBalance = useMemo(
    () =>
      isPendingLoan
        ? '...'
        : formatLoanOverviewAmountValue({
            cannotFetchDetails: loan.cannotFetchDetails,
            isPending: isPendingLoan,
            amount: loan.currentPayoffBalance,
          }),
    [isPendingLoan, loan.cannotFetchDetails, loan.currentPayoffBalance],
  );
  const showCallPracticeButton = isPendingLoan && loan.practice && loan.practice.phoneNumber;
  const loanIdSection = useMemo(
    () => (isPendingLoan ? loanId + ', Finalize loan with practice' : loanId),
    [isPendingLoan, loanId],
  );

  useEffect(() => {
    if (isAddPrimaryPaymentMethod && primaryPaymentMethodInfoLoanId === loan.id) {
      setIsOpenEnableAutopayDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lateFees = loan.currentLateFeesPaymentAmountNeedToPay
    ? formatAmount(loan.currentLateFeesPaymentAmountNeedToPay)
    : null;

  const handleOnClickLoanCard = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetId = (event.target as any).id;
    if (targetId !== toggleNodeId && loan.restructured !== 'src') {
      onClick(loanId);
    }
  };

  const handleOnToggleAutopay = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (loan.restructured === 'src') return;
    const expectToEnableAutopay = !loan.hasScheduledPayments && checked;
    const expectToDisableAutopay = loan.hasScheduledPayments && !checked;

    if (isTake5Loan) {
      showNotification(`You can't ${expectToEnableAutopay ? 'enable' : 'disable'} Autopay on a Take5 payment plan`, {
        variant: 'info',
      });
      return;
    }

    if (isPendingLoan) {
      showNotification(`You can't ${expectToEnableAutopay ? 'enable' : 'disable'} Autopay on a pending payment plan`, {
        variant: 'info',
      });
      return;
    }

    if (expectToDisableAutopay) {
      setIsOpenDisableAutopayDialog(true);
      return;
    }

    dispatch(setSelectedLoanId(loan.id));
    navigate(routes.AUTOPAY_ACTIVATION);
  };

  const handleOnDisableAutopay = () => {
    dispatch(
      toggleAutopay({
        data: {
          loanId: loan.id,
          isEnabled: false,
        },
      }),
    );
    setIsOpenDisableAutopayDialog(false);
  };

  const handleOnCloseDisableAutopayDialog = () => setIsOpenDisableAutopayDialog(false);

  const handleOnCloseEnableAutopayDialog = () => setIsOpenEnableAutopayDialog(false);

  const handleOnCloseSetPrimaryPaymentMethodDialog = () => setIsOpenSetPrimaryPaymentMethodDialog(false);

  const handleOnClickPrimaryPaymentMethod = (event: React.MouseEvent<HTMLDivElement>) => {
    if (canChangePrimaryPaymentMethod) {
      event.stopPropagation();
      setIsOpenSetPrimaryPaymentMethodDialog(true);
    }
  };

  const renderCustomizedLoadingSpinner = () => <LoadingSpinner size={15} styleName="custom loading" />;
  const restructuredSrcLoan = loan.restructured === 'src';
  return (
    <>
      <div styleName="wrapper-payment-plan" onClick={(e) => handleOnClickLoanCard(e)}>
        <div styleName="wrapper-info">
          <div styleName={classNames('block', { 'src-loan': restructuredSrcLoan })}>
            <div styleName="block practice">
              {loan.practice ? shortenString(loan.practice.name) : DEFAULT_VALUE_MISSING_FIELDS}
            </div>
            {!restructuredSrcLoan && (
              <div>
                Remaining:
                <div styleName={classNames('payoff-balance-value', { paidoff: isPaidoffLoan })}>
                  <div styleName="value payoff-balance">{payoffBalance}</div>
                  {!isPaidoffLoan && isSynchronizingLoans && renderCustomizedLoadingSpinner()}
                </div>
              </div>
            )}
          </div>

          <div styleName={classNames('block', { 'src-loan': restructuredSrcLoan })}>{loanIdSection}</div>
          {restructuredSrcLoan && (
            <div styleName={classNames('block', { 'src-loan-text': restructuredSrcLoan })}>
              Restructured to {loan.restructuredUniqueId}
            </div>
          )}

          {!!lateFees && (
            <div styleName={classNames('block', 'late-fees', { 'src-loan': restructuredSrcLoan })}>
              <div>Including late fees:</div>
              <div styleName="late-fees-value">
                <div styleName="value">{lateFees}</div>
                {!isPaidoffLoan && isSynchronizingLoans && renderCustomizedLoadingSpinner()}
              </div>
            </div>
          )}
        </div>

        {hasAutopayToggle && !restructuredSrcLoan && (
          <div styleName={classNames('block', 'wrapper-toggle-autopay', { 'src-loan': restructuredSrcLoan })}>
            <div
              styleName="wrapper-primary-payment-method-autopay"
              onClick={(e) => handleOnClickPrimaryPaymentMethod(e)}
            >
              <span styleName="label">Autopay</span>
              {canChangePrimaryPaymentMethod && (
                <PaymentMethodListItem method={loan.primaryPaymentMethod!} displayHoldername={false} />
              )}
            </div>
            {isProcessingWithAutopay ? (
              renderCustomizedLoadingSpinner()
            ) : (
              <AutopayToggle id={toggleNodeId} isEnabled={loan.hasScheduledPayments} onChange={handleOnToggleAutopay} />
            )}
          </div>
        )}
        {showCallPracticeButton && (
          <CallButton phoneNumber={loan.practice!.phoneNumber} title="Call Practice" align="left" />
        )}
      </div>
      <DisableAutopayDialog
        isOpen={isOpenDisableAutopayDialog}
        onClose={handleOnCloseDisableAutopayDialog}
        onDisableAutopay={handleOnDisableAutopay}
      />
      <EnableAutopayDialog
        isOpen={isOpenEnableAutopayDialog}
        onClose={handleOnCloseEnableAutopayDialog}
        loanId={loanId}
        isFetchingPaymentMethods={isFetchingPaymentMethods}
        useablePaymentMethods={useablePaymentMethodsByLoan}
      />
      <SetPrimaryPaymentMethodDialog
        isOpen={isOpenSetPrimaryPaymentMethodDialog}
        onClose={handleOnCloseSetPrimaryPaymentMethodDialog}
        loanId={loanId}
        isFetchingPaymentMethods={isFetchingPaymentMethods}
        useablePaymentMethods={useablePaymentMethodsByLoan}
      />
    </>
  );
};

export default PaymentPlanListItem;
