import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getDocuments } from 'actions/documents';
import { useAuthenticationSubscriber } from 'hooks';

const useDocumentsFetcher = () => {
  const dispatch = useDispatch();
  const currentUser = useAuthenticationSubscriber();

  const fetchDocuments = useCallback(() => dispatch(getDocuments()), [dispatch]);

  useEffect(() => {
    if (currentUser.scratchBorrowerId) {
      fetchDocuments();
    }
  }, [currentUser, fetchDocuments]);

  return { fetchDocuments };
};

export default useDocumentsFetcher;
