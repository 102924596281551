import { useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as primaryPaymentMethodInfoSelector from 'selectors/primaryPaymentMethodInfo';
import { setSelectedLoanId, setPrimaryPaymentMethod } from 'actions/loan';
import { setIsAddPrimaryPaymentMethod, resetPrimaryPaymentMethodInfo } from 'actions/primayPaymentMethodInfo';

import { Dialog } from 'components/widgets';
import { Props as CustomizedDialogProps } from 'components/widgets/Dialog';
import { PaymentMethodDropdown } from 'components/widgets';

import './SetPrimaryPaymentMethodDialog.scss';

export type Props = Pick<CustomizedDialogProps, 'isOpen'> & {
  loanId: string;
  isFetchingPaymentMethods: boolean;
  useablePaymentMethods: IPaymentMethod[];
  onClose: () => void;
  onSetPrimaryPaymentMethod?: (tokenId: string) => void;
};

const SetPrimaryPaymentMethodDialog = (props: Props) => {
  const { loanId, isFetchingPaymentMethods, useablePaymentMethods } = props;
  const { isAddPrimaryPaymentMethod, primaryPaymentMethodInfoLoanId, primaryPaymentMethodInfoTokenId } = useSelector(
    (state: IRootState) => ({
      isAddPrimaryPaymentMethod: primaryPaymentMethodInfoSelector.isAddPrimaryPaymentMethodSelector(state),
      primaryPaymentMethodInfoLoanId: primaryPaymentMethodInfoSelector.primaryPaymentMethodInfoLoanIdSelector(state),
      primaryPaymentMethodInfoTokenId: primaryPaymentMethodInfoSelector.primaryPaymentMethodInfoTokenIdSelector(state),
    }),
  );

  const hasJustAddedNewPrimaryPaymentMethod =
    isAddPrimaryPaymentMethod && primaryPaymentMethodInfoTokenId && primaryPaymentMethodInfoLoanId === loanId;
  const hasOnlyOnePaymentMethod = useablePaymentMethods.length && useablePaymentMethods.length === 1;
  const initialSelectedTokenId = hasJustAddedNewPrimaryPaymentMethod
    ? primaryPaymentMethodInfoTokenId
    : hasOnlyOnePaymentMethod
    ? useablePaymentMethods[0].id
    : '';
  const [selectedTokenId, setSelectedTokenId] = useState(initialSelectedTokenId);
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const dispatch = useDispatch();

  const handleOnChangePaymentMethod = (event: ChangeEvent<HTMLInputElement>) => {
    const nextTokenId = event.target.value;
    if (!nextTokenId || nextTokenId === selectedTokenId) return;

    setSelectedTokenId(nextTokenId);
  };

  const handleOnBeforeGoToAddPaymentMethod = () => {
    dispatch(setSelectedLoanId(loanId));
    dispatch(setIsAddPrimaryPaymentMethod(true));
  };

  const handleOnClose = () => {
    dispatch(resetPrimaryPaymentMethodInfo());
    props.onClose();
  };

  const handleOnSetPrimaryPaymentMethodAndEnableAutopay = () => {
    if (!selectedTokenId) {
      setErrorMessage('Please add a payment method');
      return;
    }

    dispatch(resetPrimaryPaymentMethodInfo());
    if (!props.onSetPrimaryPaymentMethod) {
      dispatch(
        setPrimaryPaymentMethod({
          data: {
            loanId,
            tokenId: selectedTokenId,
          },
        }),
      );
    } else {
      props.onSetPrimaryPaymentMethod(selectedTokenId);
    }
    props.onClose();
  };

  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={handleOnClose}
      title="Select or add a payment method"
      actions={[
        {
          text: 'Cancel',
          type: 'negative',
          onClick: handleOnClose,
        },
        {
          text: 'Set as primary',
          onClick: handleOnSetPrimaryPaymentMethodAndEnableAutopay,
        },
      ]}
    >
      <>
        <p styleName="set-primary-payment-method-info">
          Choose the primary payment method or touch the drop down menu to add one. This payment method will then be
          charged on each due date.
        </p>
        <p styleName="set-primary-payment-method-info">
          <span>Note</span>: avoid a convenience fee with ACH payments.
        </p>
        <PaymentMethodDropdown
          isFetching={isFetchingPaymentMethods}
          paymentMethods={useablePaymentMethods}
          value={selectedTokenId}
          error={!!errorMessage}
          helperText={errorMessage}
          onChange={handleOnChangePaymentMethod}
          onBeforeGoToAddPaymentMethod={handleOnBeforeGoToAddPaymentMethod}
        />
      </>
    </Dialog>
  );
};

export default SetPrimaryPaymentMethodDialog;
