import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { usePaymentMethodFetcher, useSelectedLoan, useClientStorage } from 'hooks';
import { makePayment, IMakePaymentData } from 'actions/payment';
import * as loanSelectors from 'selectors/loan';
import * as paymentMethodSelectors from 'selectors/paymentMethod';
import * as paymentSelectors from 'selectors/payment';
import { routes } from 'utils/routeHelper';
import { showNotification } from 'utils/notification';
import { getPaymentInputSessionKey } from 'utils/loan';

import { Header, Footer, BodyLayout } from 'components/layouts';
import { PaymentDetailsBody, Col, LoadingSpinner } from 'components/widgets';

import './PaymentDetails.scss';

const TRANSLATING_ERROR_MESSAGE: Record<string, string> = {
  balance_insufficient:
    'Your account balance is insufficient. Top-up your account or try with a different payment method',
};

export const initializeState = (state: IRootState) => {
  const payableLoans = loanSelectors.payableLoansSelector(state);
  const payableLoanById = loanSelectors.payableLoanByIdSelector(state);
  const nextScheduledPaymentByLoanId = paymentSelectors.nextScheduledPaymentByLoanIdSelector(state);

  return {
    isFetchedLoans: loanSelectors.isFetchedSelector(state),
    isFetchingLoans: loanSelectors.isFetchingSelector(state),
    isFetchingPaymentMethods: paymentMethodSelectors.isFetchingSelector(state),
    payableLoans,
    payableLoanById,
    stripePaymentMethods: paymentMethodSelectors.stripePaymentMethodsSelector(state),
    payixPaymentMethods: paymentMethodSelectors.payixPaymentMethodsSelector(state),
    nextScheduledPaymentByLoanId,
  };
};

const PaymentDetails = () => {
  const { fetchPaymentMethods } = usePaymentMethodFetcher();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedLoan = useSelectedLoan();
  const {
    isFetchedLoans,
    isFetchingPaymentMethods,
    payableLoanById,
    stripePaymentMethods,
    payixPaymentMethods,
    nextScheduledPaymentByLoanId,
  } = useSelector(initializeState);
  const { handleRemoveStorageItem } = useClientStorage('session', getPaymentInputSessionKey(selectedLoan));
  const [isFetchingMakePayment, setIsFetchingMakePayment] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleLoadPaymentMethods = () => fetchPaymentMethods();
  const handleOnConfirmAndPay = (data: IMakePaymentData, options: { isFullPayoffAmount: boolean }) => {
    setIsFetchingMakePayment(true);
    setErrorMessage('');

    dispatch(
      makePayment({
        data,
        onSuccess: () => {
          showNotification('Payment sent. It may take a few minutes to refresh.', { variant: 'success' });
          handleRemoveStorageItem();
          if (options.isFullPayoffAmount) {
            navigate(routes.LOAN_PAID_OFF);
          } else {
            navigate(routes.HOME);
          }
        },
        onError: (errorMsg: string) => {
          const translatedErrorMessage = TRANSLATING_ERROR_MESSAGE[errorMsg] || errorMsg;
          showNotification('There was an issue with your payment', { variant: 'error' });
          setErrorMessage(translatedErrorMessage);
          setIsFetchingMakePayment(false);
        },
      }),
    );
  };

  if (selectedLoan && payableLoanById[selectedLoan.id]) {
    return (
      <div styleName="wrapper">
        <Header styleOptions={{ isSticky: false, isLight: true }} />
        <BodyLayout heading="Make a payment" subHeading={`Loan ID ${selectedLoan.id}`}>
          <PaymentDetailsBody
            isFetchingPaymentMethods={isFetchingPaymentMethods}
            isFetchingMakePayment={isFetchingMakePayment}
            selectedPayableLoan={selectedLoan}
            nextScheduledPaymentByLoanId={nextScheduledPaymentByLoanId}
            stripePaymentMethods={stripePaymentMethods}
            payixPaymentMethods={payixPaymentMethods}
            onLoadPaymentMethod={handleLoadPaymentMethods}
            onConfirmAndPay={handleOnConfirmAndPay}
            errorMessage={errorMessage}
          />
        </BodyLayout>
        <Footer />
      </div>
    );
  }

  if (!isFetchedLoans) {
    return (
      <div styleName="wrapper">
        <Header />
        <Col xs={12} sm={6} md={4} styleName="col">
          <LoadingSpinner />
        </Col>
      </div>
    );
  }

  return <Navigate to={routes.HOME} replace />;
};

export default PaymentDetails;
