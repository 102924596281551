import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Header } from 'components/layouts';
import { Col, LoadingSpinner } from 'components/widgets';

import './MyDocuments.scss';
import useDocumentsFetcher from 'hooks/useDocumentsFetcher';
import * as documentsSelectors from 'selectors/documents';
import { formatDate } from 'utils/format';
import { downloadDocument } from 'actions/documents';
import { showNotification } from 'utils/notification';
import { getCompleteFileName, saveFile } from 'utils/file';
import TablePagination from '@mui/material/TablePagination';

export const MyDocuments = () => {
  useDocumentsFetcher();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [requestSentForDocuments, setRequests] = useState([] as string[]);

  const { isFetchedDocuments, isFetchingDocuments, loanDocuments, totalDocuments } = useSelector(
    (state: IRootState) => {
      return {
        isFetchedDocuments: documentsSelectors.isFetchedDocumentsSelector(state),
        isFetchingDocuments: documentsSelectors.isFetchingDocumentsSelector(state),
        loanDocuments: documentsSelectors.documentsSelector(state),
        totalDocuments: documentsSelectors.totalDocumentsSelector(state),
      };
    },
  );

  const hasDocuments = !!loanDocuments.length;

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const showDivider = (totalDocuments: number, currentIndex: number) => {
    return currentIndex + 1 < totalDocuments;
  };

  const getLoanDocuments = () => {
    const startIndex = page * rowsPerPage;
    return loanDocuments.slice(startIndex, startIndex + rowsPerPage);
  };

  const isDownloading = (link: string): boolean => {
    return requestSentForDocuments.includes(link);
  };

  const updateRequestSentArray = (link: string) => {
    const requests = [...requestSentForDocuments];
    const index = requests.indexOf(link);
    if (index >= 0) {
      requests.splice(index, 1);
      setRequests(requests);
    }
  };

  const downloadLoanDocument = async (loanDocument: ILoanDocument) => {
    requestSentForDocuments.push(loanDocument.link);
    dispatch(
      downloadDocument({
        data: loanDocument,
        onSuccess: async (file, data) => {
          const buffer = Buffer.from(file.data);
          const fileName = getCompleteFileName(data.name, data.link);
          const fileSavedSuccessfully = await saveFile(buffer, fileName);
          if (fileSavedSuccessfully) {
            showNotification('Document downloaded Successfully!!', { variant: 'success' });
          } else {
            showNotification('Something went wrong while saving file! Please check app permissions', {
              variant: 'error',
            });
          }
          updateRequestSentArray(data.link);
        },
        onError: (errorMsg: string, data) => {
          showNotification('There was an issue during download!', { variant: 'error' });
          updateRequestSentArray(data.link);
        },
      }),
    );
  };

  const renderdocuments = () => {
    if (!hasDocuments) {
      return (
        <div styleName="no-data-wrapper">
          You don’t have any Documents yet. This is where you will see the list of all your documents.
        </div>
      );
    }

    return (
      <div styleName="container">
        <Badge color="secondary" badgeContent={totalDocuments} showZero>
          <Typography styleName="header">My Documents</Typography>
        </Badge>

        <div styleName="loan-documents">
          {getLoanDocuments().map((loanDocument: ILoanDocuments) => (
            <Accordion key={loanDocument.loanId}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography styleName="loan-header">Loan ID {loanDocument.loanId}</Typography>
              </AccordionSummary>
              <AccordionDetails styleName="expansion-details">
                <List styleName="list-root">
                  {loanDocument.documents.map((document, index) => (
                    <div key={document.id + '_id'}>
                      <ListItem key={document.id}>
                        <ListItemText
                          primary={document.name}
                          key={document.name}
                          secondary={formatDate(document.date)}
                        />
                        <Button
                          aria-label="download"
                          title="Download document"
                          variant="contained"
                          color="secondary"
                          onClick={() => downloadLoanDocument(document)}
                          disabled={isDownloading(document.link)}
                          key={document.name + '_link'}
                        >
                          {isDownloading(document.link) ? 'Downloading...' : 'Download'}
                        </Button>
                      </ListItem>
                      {showDivider(loanDocument.documents.length, index) && <Divider />}
                    </div>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>

        <TablePagination
          component={'div'}
          count={loanDocuments.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[rowsPerPage]}
          page={page}
          onPageChange={handleChangePage}
        />
      </div>
    );
  };

  return (
    <Col xs={12} sm={6} md={6} styleName="wrapper">
      <Header />
      {isFetchingDocuments && <LoadingSpinner />}
      {isFetchedDocuments && renderdocuments()}
    </Col>
  );
};

export default MyDocuments;
