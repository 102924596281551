import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getPayments } from 'actions/payment';
import { useAuthenticationSubscriber } from 'hooks';

const usePaymentFetcher = () => {
  const dispatch = useDispatch();
  const currentUser = useAuthenticationSubscriber();

  const fetchPayments = useCallback(() => dispatch(getPayments()), [dispatch]);

  useEffect(() => {
    if (currentUser.scratchBorrowerId) {
      fetchPayments();
    }
  }, [currentUser, fetchPayments]);

  return { fetchPayments };
};

export default usePaymentFetcher;
