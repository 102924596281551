import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { renewBorrower } from 'actions/user';
import { useAuthenticationSubscriber } from 'hooks';

const useBorrowerFetcher = () => {
  const dispatch = useDispatch();
  const currentUser = useAuthenticationSubscriber();

  const fetchBorrower = useCallback(() => dispatch(renewBorrower()), [dispatch]);

  useEffect(() => {
    if (currentUser.scratchBorrowerId) {
      fetchBorrower();
    }
  }, [currentUser, fetchBorrower]);

  return { fetchBorrower };
};

export default useBorrowerFetcher;
