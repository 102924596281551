import { getOwedAmountInDollars } from 'utils/loan';
import useSelectedLoan from './useSelectedLoan';
import { useSelector } from 'react-redux';
import * as loanSelectors from 'selectors/loan';
import * as convenienceFeeSelectors from 'selectors/convenienceFee';

const usePayoffAmount = () => {
  let selectedLoan = useSelectedLoan();
  const { nextScheduledPaymentDue, isFetchingConvenienceFee, convenienceFeeValue, lateFeeValue } = useSelector(
    (state: IRootState) => {
      const isFetched = loanSelectors.isFetchedSelector(state);
      if (isFetched && !selectedLoan) {
        selectedLoan = loanSelectors.loansSelector(state)[0];
      }
      const convenienceFee = convenienceFeeSelectors.convenienceFeeSelector(state);
      const convenienceFeeValue = convenienceFee.value / convenienceFee.unit || 0;

      return {
        ...(selectedLoan
          ? {
              nextScheduledPaymentDue: loanSelectors.getNextPaymentDueSelector(selectedLoan.id)(state),
              isFetchingConvenienceFee: convenienceFeeSelectors.isFetchingConvenienceFeeSelector(state),
              convenienceFeeValue,
              lateFeeValue: selectedLoan.currentLateFeesPaymentAmountNeedToPay || 0,
            }
          : {
              nextScheduledPaymentDue: null,
              isFetchingConvenienceFee: false,
              convenienceFeeValue: 0,
              lateFeeValue: 0,
            }),
      };
    },
  );

  let isPayoffAmount = false;
  let paymentAmount: number;
  const owedAmount = getOwedAmountInDollars(nextScheduledPaymentDue);
  if (selectedLoan && selectedLoan.currentPayoffBalance && owedAmount > selectedLoan.currentPayoffBalance) {
    isPayoffAmount = true;
    paymentAmount = selectedLoan.currentPayoffBalance;
  } else {
    paymentAmount = owedAmount;
  }

  // useful for displaying on UI
  const additionalFees = convenienceFeeValue + lateFeeValue;

  // actual amount sent to PS, convenience fee not included here
  const actualNextInstallment = paymentAmount + lateFeeValue;
  // amount displayed to user
  const totalNextInstallment = actualNextInstallment + convenienceFeeValue;

  return {
    isPayoffAmount,
    paymentAmount,
    isFetchingConvenienceFee,
    convenienceFeeValue,
    lateFeeValue,
    additionalFees,
    actualNextInstallment,
    totalNextInstallment,
  };
};

export default usePayoffAmount;
